export default [
  // {
  //   title: 'Products',
  //   route: 'apps-products-list',
  //   icon: 'GridIcon'
  // },
  // {
  //   title: 'Orders',
  //   route: 'apps-orders-list',
  //   icon: 'ShoppingCartIcon'
  // },
  // {
  //   title: 'Banners',
  //   route: 'apps-banners',
  //   icon: 'ImageIcon',
  // },
  {
    title: "Talent Categories",
    route: "apps-categories",
    icon: "AwardIcon",
  },
  {
    title: "Manage Positions",
    route: "apps-pricemanagement",
    icon: "GridIcon",
  },
  {
    title: "Teams",
    route: "apps-font",
    icon: "FlagIcon",
  },
  {
    title: "Stars",
    route: "apps-gift",
    icon: "StarIcon",
  },
  {
    title: "Talents",
    route: "apps-subcategories",
    icon: "UserIcon",
  },
  {
    title: "Talents (Under 18)",
    route: "apps-banners",
    icon: "AlertCircleIcon",
  },
  {
    title: "Scouts",
    route: "apps-regions",
    icon: "UserIcon",
  },
  {
    title: "Packages",
    route: "apps-ads",
    icon: "BoxIcon",
  },
  {
    title: "Featured Banners",
    route: "apps-advertise",
    icon: "ServerIcon",
  },
  {
    title: "Users Management",
    route: "apps-admins",
    icon: "UsersIcon",
  },
  {
    title: "Rewards",
    route: "apps-promocode",
    icon: "GiftIcon",
  },
  {
    title: "Reels Management",
    route: "apps-payout",
    icon: "VideoIcon",
  },
  {
    title: "News",
    route: "apps-vendor",
    icon: "TvIcon",
  },
  {
    title: "Communities",
    route: "apps-myuser",
    icon: "UsersIcon",
  },
  {
    title: "Recruitment Requests",
    route: "apps-requests",
    icon: "DatabaseIcon",
  },
  {
    title: "Posts",
    route: {
      name: "pages-profile",
      params: { id: 0 },
    },
    icon: "ImageIcon",
  },
  {
    title: "Reports",
    route: "apps-reports",
    icon: "AlertTriangleIcon",
  },
  {
    title: "Referrals",
    route: "apps-referrals",
    icon: "MenuIcon",
  },
  {
    title: "Terms & Conditions",
    route: "apps-terms",
    icon: "FileIcon",
  },
  {
    title: "Privacy Policy",
    route: "apps-privacy",
    icon: "ApertureIcon",
  },
  {
    title: "Change Password",
    route: "auth-reset-password-v1",
    icon: "LockIcon",
  },
  {
    title: "Broadcast Message",
    route: "apps-occasions",
    icon: "MessageSquareIcon",
  },
  {
    title: "Countries",
    route: "apps-country",
    icon: "MapIcon",
  },
  {
    title: "Notifications",
    route: "apps-notifications",
    icon: "BellIcon",
  },
  // {
  //   title: 'Voucher',
  //   route: 'apps-promocode',
  //   icon: 'StarIcon',
  // },
  // {
  //   title: 'Vendors',
  //   route: 'apps-vendor',
  //   icon: 'UserIcon',
  // },
  // {
  //   title: 'Venues',
  //   route: 'apps-banners',
  //   icon: 'HomeIcon',
  // },
  // {
  //   title: 'Sub categories',
  //   route: 'apps-subcategories',
  //   icon: 'ListIcon',
  // },
  // {
  //   title: 'Zone Management',
  //   icon: 'MapPinIcon',
  //   children: [
  //     {
  //       title: 'City',
  //       route: 'apps-zonemanagement',
  //     },
  //     {
  //       title: 'Country',
  //       route: 'apps-country',
  //     },
  //     {
  //       title: 'Region',
  //       route: 'apps-regions',
  //     },
  //   ],
  // },
  // {
  //   title: 'Products',
  //   route: 'apps-all-products',
  //   icon: 'GridIcon',
  // },

  // {
  //   title: 'Vendors',
  //   route: 'apps-all-vendors',
  //   icon: 'UsersIcon',
  // },
  // {
  //   title: 'Promo code',
  //   route: 'apps-promocode',
  //   icon: 'StarIcon',
  // },

  // {
  //   title: 'Bookings',
  //   route: 'apps-all-orders',
  //   icon: 'ShoppingBagIcon',
  // },

  // {
  //   title: 'Order status',
  //   route: 'apps-orderstatus',
  //   icon: 'TagIcon',
  // },
  // {
  //   title: 'Messages',
  //   route: 'apps-chat',
  //   icon: 'MessageSquareIcon',
  // },
  // {
  //   title: 'User',
  //   route: 'apps-myuser',
  //   icon: 'UserIcon',
  // },

  // {
  //   title: 'Payout',
  //   route: 'apps-payout',
  //   icon: 'CreditCardIcon',
  // },
  // {
  //   title: 'Manage Ads',
  //   route: 'apps-ads',
  //   icon: 'TvIcon',
  // },

  // {
  //   title: 'Delivery Charges',
  //   route: 'apps-pricemanagement',
  //   icon: 'TruckIcon',
  // },

  // {
  //   title: 'City',
  //   route: 'apps-zonemanagement',
  //   icon: 'MapPinIcon',
  // },
  // {
  //   title: 'Country',
  //   route: 'apps-zonemanagement',
  //   icon: 'MapPinIcon',
  // },
  // {
  //   title: 'Region',
  //   route: 'apps-regions',
  //   icon: 'MapPinIcon',
  // },

  // {
  //   title: 'Seller Registration',
  //   route: null,
  //   icon: 'UserPlusIcon',
  //   action:'read',
  //   resourse:''
  // },
  // {
  //   title: 'Reports',
  //   route: 'apps-todo',
  //   icon: 'BarChart2Icon',
  // },
  // {
  //   title: 'Setting',
  //   route: '#',
  //   icon: 'SettingsIcon',
  // },
];
